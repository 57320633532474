import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import { AWS_BUCKET_URL } from 'toolkit/constants';
import {
  gameDateToMatchDay,
  gameDateToMatchTime,
  getMatchTime,
  checkHasMarketVariable,
  sportTypeToSportId
} from 'toolkit/utils.js';
import { EnumMarketName, EnumEventStatus, EnumMatchStatusName, EnumMarketStatus } from 'toolkit/Enums.js';
import { getTeamNameHelper } from 'helper/matchDetailHelper';
import BetTitleAndBox from './bet_title_and_box.js';

function MatchCard(props) {
  const { t } = useTranslation('translations', { useSuspense: false });
  const { providerId } = props;
  if (!providerId) return null;
  const match = useSelector((state) => state.match.events[providerId]);
  if (!match || match.isMatch === false) return null;
  const activeMarkets = match.markets.filter(x => x.marketStatus === EnumMarketStatus.ACTIVE);

  const defaultMarketObject = {
    1: ['1', 'X', '2'],
    7: ['1', 'X', '2'],
    8: ['1', 'X', '2'],
    10: ['1X', '12', 'X2'],
    14: ['1', 'X', '2'],
    18: ['+', '-'],
    29: ['Yes', 'No'],
    186: ['1', '2']
  };

  const sortByMarketId = (arr) => {
    return _.orderBy(arr, (market) => {
      if (market === null || market === undefined) return false;
      const marketVariable = checkHasMarketVariable(market.id, market.stringId);
      if (marketVariable) {
        if (!isNaN(Number(marketVariable))) return Number(marketVariable);
        return marketVariable;
      }
      return false;
    });
  };

  const getDefaultMarket = () => {
    // hide bettitle if match card doesn't have the selected market
    if (props.hideIfNoMarket) {
      return null;
    }
    let defaultMarket;
    const winnerBet = match.markets.find((m) => m.id === 1);
    if (winnerBet) {
      defaultMarket = winnerBet;
    } else {
      const otherWinningIds = [7, 113, 114, 186, 219, 340, 251];
      defaultMarket = match.markets.find((m) => otherWinningIds.includes(m.id));
    }
    if (!defaultMarket) {
      defaultMarket = {
        id: 186,
        name: '',
        outcomeType: '',
        stringId: '',
        outcomes: defaultMarketObject[186],
        renderNull: true
      };
    }
    return defaultMarket;
  };

  function MatchCardGetTitle() {
    const sportType = match.sport;
    const providerId = match.providerId;
    let marketType = props.marketType;
    let markets = [];
    if (!match.markets || match.markets.length === 0) {
      return (
        <div className="d-flex justify-content-center align-item-center">
          {t('components.matchcard.noMarket')}
        </div>
      );
    } else if (sportType === 0) {
      if (
        match.matchStatusId == EnumMatchStatusName.FIRST_EXTRA ||
        match.matchStatusId == EnumMatchStatusName.SECOND_EXTRA
      ) {
        if (marketType === 7 && match.markets.find((x) => x.id === 114)) {
          marketType = 114;
        } else if (marketType === 1 && match.markets.find((x) => x.id === 113)) {
          marketType = 113;
        } else if (marketType === 8 && match.markets.find((x) => x.id === 115)) {
          marketType = 115;
        }
      }

      const filteredMarkets = match.markets.filter((x) => x.id === marketType);
      if (filteredMarkets.length === 0) {
        if (props.lockIfNoMarket) {
          markets.push({
            id: marketType,
            name: '',
            outcomeType: '',
            stringId: '',
            outcomes: defaultMarketObject[marketType],
            renderNull: true
          });
        } else {
          markets.push({
            id: marketType,
            name: '',
            outcomeType: '',
            stringId: '',
            outcomes: defaultMarketObject[marketType],
            renderNull: true
          });
        }
      } else {
        markets.push(...filteredMarkets);
      }
    } else {
      markets.push(getDefaultMarket());
    }

    markets = markets.sort(
      (t1, t2) => Number(t1.stringId.split('-S:')[1]) - Number(t2.stringId.split('-S:')[1])
    );

    if (marketType === 14) {
      markets = sortByMarketId(markets);
    }
    return markets.map((market, index) => (
      <BetTitleAndBox
        key={index}
        market={market}
        matchId={providerId}
        sportType={sportType}
        lockIfNoMarket={props.lockIfNoMarket}
      />
    ));
  }

  // if (!getDefaultMarket()) {
  //   return <div></div>;
  // }

  const servingBall =
    match.sport == sportTypeToSportId('Volleyball') ? (
      <img className="serving-ball" src={`${AWS_BUCKET_URL}/Icons/182.png`} />
    ) : (
      <img className="serving-ball" src={`${AWS_BUCKET_URL}/Icons/118.png`} />
    );
  const homeScores = [];
  const awayScores = [];
  const periodScoresLenght = match.periodScores.length;
  match.periodScores.forEach((element, index) => {
    if (index === periodScoresLenght - 1) {
      homeScores.push(
        <span className="text-success" key={'homeScore1'}>
          {element.homeScore}
        </span>
      );
      awayScores.push(
        <span className="text-success" key={'awayScore1'}>
          {element.awayScore}
        </span>
      );
    } else {
      homeScores.push(<span key={'homeScore2'}>{element.homeScore} </span>);
      awayScores.push(<span key={'awayScore2'}>{element.awayScore} </span>);
    }
  });

  const homeRedCardCount = (
    <span className="badge bg-danger ms-2 px-1">{match.homeRedCardCount}</span>
  );
  const awayRedCardCount = (
    <span className="badge bg-danger ms-2 px-1">{match.awayRedCardCount}</span>
  );
  const getTeam = (team) => {
    const lastPeriodScore = match.periodScores[match.periodScores.length - 1];
    const homeServingBall =
      lastPeriodScore && lastPeriodScore.homeScore > lastPeriodScore.awayScore;
    const awayShowServingBall =
      lastPeriodScore && lastPeriodScore.homeScore <= lastPeriodScore.awayScore;
    const redCardCountSource = team == 'team1' ? 'homeRedCardCount' : 'awayRedCardCount';

    const teamName = getTeamNameHelper(
      props.locales,
      props.selectedLocale,
      match.competitors[team == 'team1' ? 0 : 1],
      t
    );
    const showServingBall =
      team == 'team1' ? homeServingBall && servingBall : awayShowServingBall && servingBall;
    return (
      <span>
        {teamName} {showServingBall}
        {match[redCardCountSource] > 0
          ? team == 'team1'
            ? homeRedCardCount
            : awayRedCardCount
          : null}
      </span>
    );
  };

  const getScore = (team) => {
    if (match.eventStatus == EnumEventStatus.RUNNING) {
      if (
        match.sport == sportTypeToSportId('Volleyball') ||
        match.sport == sportTypeToSportId('Tennis') ||
        match.sport == sportTypeToSportId('TableTennis')
      ) {
        if (props.desktop) {
          return (
            <span className="float-end text-color-green ps-3">
              {team == 'team1' ? homeScores : awayScores}
            </span>
          );
        } else {
          return <span className="float-end">{team == 'team1' ? homeScores : awayScores}</span>;
        }
      } else {
        if (props.desktop) {
          if (match.matchStatusId == EnumMatchStatusName.HALFTIME) {
            return (
              <span className="float-end text-color-blue ps-3">
                {team == 'team1' ? match.homeScore : match.awayScore}
              </span>
            );
          } else {
            return (
              <span className="float-end text-color-green ps-3">
                {team == 'team1' ? match.homeScore : match.awayScore}
              </span>
            );
          }
        } else {
          return (
            <span className="float-end">{team == 'team1' ? match.homeScore : match.awayScore}</span>
          );
        }
      }
    } else {
      return null;
    }
  };

  const getTeams = (team) => {
    const redCardCountSource = team == 'team1' ? 'homeRedCardCount' : 'awayRedCardCount';
    const teamName = getTeamNameHelper(
      props.locales,
      props.selectedLocale,
      match.competitors[team == 'team1' ? 0 : 1],
      t
    );
    return (
      <div>
        {match.sport == sportTypeToSportId('Volleyball') ||
          match.sport == sportTypeToSportId('Tennis') ||
          match.sport == sportTypeToSportId('TableTennis') ? (
          getTeam(team)
        ) : (
          <span>
            {teamName}
            {match[redCardCountSource] > 0
              ? team == 'team1'
                ? homeRedCardCount
                : awayRedCardCount
              : null}
          </span>
        )}
        {props.desktop ? null : getScore(team)}
      </div>
    );
  };

  const getDesktopMatchCardMarkets = () => {
    const findOddsRowMarket = (markets, marketId, isLiveEvent = false) => {
      if (
        marketId === EnumMarketName.TOTAL_18 ||
        marketId === EnumMarketName.FIRSTHALFOVERUNDER_68
      ) {
        const sameMarkets = _.orderBy(
          markets.filter((m) => m.id === marketId),
          ['stringId'],
          ['asc']
        );
        if (isLiveEvent && marketId === EnumMarketName.FIRSTHALFOVERUNDER_68) {
          return sameMarkets[0];
        }
        const index = Math.floor((sameMarkets.length - 1) / 2);
        return sameMarkets[index];
      }
      if (
        marketId === EnumMarketName.HANDICAP_14 ||
        marketId === EnumMarketName.FIRSTHALFHANDICAP_65
      ) {
        // example result 0:1 or 1:0
        // use 0 or 2 character for comparison
        const oddsMarkets = _.orderBy(
          markets.filter((m) => m.id === marketId),
          ['stringId'],
          ['asc']
        );
        const homeHandicaps = oddsMarkets.filter((m) => m.stringId.split('-S:')[1][2] === '0');
        const awayHandicaps = oddsMarkets.filter((m) => m.stringId.split('-S:')[1][0] === '0');
        const diff = homeHandicaps.length - awayHandicaps.length;
        // console.log(homeHandicaps.length, awayHandicaps.length);
        if (diff === 0 && homeHandicaps.length > 0 && awayHandicaps.length > 0) {
          const homeHandicapSmallestOdds = _.orderBy(
            homeHandicaps[0].outcomes,
            ['odds'],
            ['asc']
          )[0];
          const awayHandicapSmallestOdds = _.orderBy(
            awayHandicaps[0].outcomes,
            ['odds'],
            ['asc']
          )[0];
          return homeHandicapSmallestOdds.odds <= awayHandicapSmallestOdds.odds
            ? homeHandicaps[0]
            : awayHandicaps[0];
        }
        return diff > 0
          ? homeHandicaps[Math.abs(diff) === 1 || Math.abs(diff) === 2 ? 0 : Math.abs(diff) - 1]
          : awayHandicaps[Math.abs(diff) === 1 || Math.abs(diff) === 2 ? 0 : Math.abs(diff) - 1];
      }
      return markets.find((m) => m.id === marketId);
    };
    return props.desktopMarkets?.map((marketId) => (
      <div className="bet-group" key={marketId}>
        <BetTitleAndBox
          market={findOddsRowMarket(match.markets, marketId)}
          marketId={marketId}
          matchId={match.providerId}
          sportType={match.sport}
          lockIfNoMarket={props.lockIfNoMarket}
          hideMarketLabel={props.hideMarketLabel}
        />
      </div>
    ));
  };

  if (props.desktop) {
    return (
      <div className="card card-body desktop-match-card text-white border-card border-2">
        <div className="time">
          <div className="d-flex flex-column lh-sm text-start fw-semibold">
            <small className=" fw-bold">{gameDateToMatchDay(match.gameDate)}</small>

            <small className="time">{gameDateToMatchTime(match.gameDate)}</small>
          </div>
          <div className="d-flex gap-1">
            <span onClick={() => props.onMatchTrackerSet(match)}>
              <img width="25" height="20" src={`${AWS_BUCKET_URL}/Icons/stadyum2.png`} />
            </span>
          </div>
        </div>
        <div className="teams-group">
          <div className="text-truncate d-flex justify-content-between">
            {getTeams('team1')}

            <span
              className={
                match.matchStatusId == EnumMatchStatusName.HALFTIME
                  ? 'text-color-blue float-end text-end'
                  : 'text-color-green float-end text-end'
              }>
              {match.eventStatus == EnumEventStatus.RUNNING ? (
                match.sport === sportTypeToSportId('Football') ? (
                  getMatchTime(match, t)
                ) : (
                  <span className="time">
                    {t(`components.matchcard.time.byMatchStatusId.${match.matchStatusId}`)}
                  </span>
                )
              ) : (
                ''
              )}
              {getScore('team1')}
            </span>
          </div>
          <div className="text-truncate d-flex justify-content-between">
            {getTeams('team2')}
            <span>{getScore('team2')}</span>
          </div>
        </div>
        {match.eventStatus == EnumEventStatus.RUNNING ? (
          ''
        ) : gameDateToMatchDay(match.gameDate).localeCompare(t('today')) == 0 ? (
          <img
            width="30"
            height="30"
            style={{ marginTop: '1rem' }}
            src={`${AWS_BUCKET_URL}/Icons/liveTime.png`}
          />
        ) : (
          <img
            width="30"
            height="30"
            style={{ marginTop: '1rem' }}
            src={`${AWS_BUCKET_URL}/Icons/time.png`}
          />
        )}
        <div className="bets-wrapper">{getDesktopMatchCardMarkets()}</div>
        <div className="match-details-link-wrapper">
          <button
            className="btn btn-link text-decoration-none d-flex flex-nowrap"
            onClick={() => props.onMatchDetailIdSet(match.providerId)}
            style={{ marginTop: '0.76rem' }}>
            <span className="text-white">{match.marketCount}</span>
            <span>
              <img
                className="icon-white"
                width="20"
                height="20"
                src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`}
              />
            </span>
          </button>
        </div>
      </div>
    );
  }

  return (
    <Link
      to={{
        pathname: '/match-detail',
        hash: `#${match.providerId}`
      }}>
      <div className="border-gray-300 px-2 py-2 rounded mb-2">
        <div className="d-flex justify-content-between mb-1 ms-n2">
          <div className="d-flex text-center lh-sm">
            {match.eventStatus > EnumEventStatus.NOTSTARTED ? (
              <span className="px-2 py-1 bg-dark-green text-white fs-8">
                {t('components.matchcard.live')}
              </span>
            ) : (
              <span className="px-2 py-1 match-date-bg-color text-white fs-8 min-w-40">
                {gameDateToMatchDay(match.gameDate)}
              </span>
            )}
            {match.eventStatus > EnumEventStatus.NOTSTARTED ? (
              match.sport === sportTypeToSportId('Football') ? (
                <span className="px-2 py-1 rounded-end-5 bg-dark text-white fs-8">
                  {getMatchTime(match, t)}
                </span>
              ) : (
                <span className="px-2 py-1 rounded-end-5 bg-dark text-white fs-8">
                  {t(`components.matchcard.time.byMatchStatusId.${match.matchStatusId}`)}
                </span>
              )
            ) : (
              <span
                className={`px-2 py-1 rounded-end-5 text-white fs-8 ${
                  match.bookingStatus === 2 ? 'wil-be-live' : 'bg-dark'
                 }`}>
                {gameDateToMatchTime(match.gameDate)}
              </span>
            )}
          </div>
          {/* <Col className="pr-0" xs={2}>
          <span className="mr-2">{NotificationIcon}</span>
          <span className="favourite">{StarDefault}</span>
        </Col> */}
          <div className="text-end text-dark fs-8">
            {activeMarkets.length}
            {props.count}{' '}
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </div>
        </div>
        <Row>
          <div className="text-dark fs-6">
            {getTeams('team1')}
            {getTeams('team2')}
          </div>
        </Row>
        <div>
          {/* match card main markets */}
          {MatchCardGetTitle()}
        </div>
      </div>
    </Link>
  );
}
export default MatchCard;
