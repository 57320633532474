import Loading from 'components/general/loading';
import QRScanner from 'components/general/qr-scanner';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { fetchBalance } from 'stores/auth';
import { addDepositCardQRReq } from 'stores/auth/services';

export default function QRDeposit() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showQrScanner, setShowQrScanner] = useState(true);
  const [error, setError] = useState(false);
  const [errortext, setErrorText] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  function handleQRResult(result) {
    if (!result) {
      return;
    }
    console.log('QR Code Result: ', result);
    setShowQrScanner(false);

    postDepositRequest(result);
  }

  async function postDepositRequest(qrCodeResult) {
    try {
      setLoading(true);
      console.log(qrCodeResult);

      const response = await addDepositCardQRReq({
        barcode: qrCodeResult
      });
      console.log('response', response);
      dispatch(fetchBalance());
      setTimeout(() => {
        navigate('/');
      }, 5000);
    } catch (error) {
      console.error('Error in deposit request: ', error);
      setError(true);
      setErrorText(t('pages.qrDeposit.error'));
    }
    setLoading(false);
  }

  useEffect(() => {
    if (user?.isPrintBarcodeEnabled === false) {
      navigate('/');
    }
  }, [user]);

  return (
    <div className="my-4 container-sm">
      {showQrScanner ? (
        <>
          <QRScanner onResult={handleQRResult} />
        </>
      ) : (
        <div>
          {error ? (
            <div className="text-red-500 text-center">{errortext}</div>
          ) : (
            t('pages.qrDeposit.successMessage')
          )}
        </div>
      )}

      {loading && <Loading />}
    </div>
  );
}
